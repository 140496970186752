<script>
// Extensions
import View from '@/views/View'

// Mixins
import LoadSections from '@/mixins/load-sections'

export default {
   name: 'Visitor',

   metaInfo: { title: 'Visitor' },

   extends: View,

   mixins: [
      LoadSections([
         'page-visitor',
      ]),
   ],

   props: {
      id: {
         type: String,
         default: 'visitor',
      },
   },
}
</script>
